import { createClient } from '@supabase/supabase-js'
import {
  slugify,
  formatDate,
  triggerVercelBuild,
  setCookie,
  getCookie,
} from './utils'

/* Check auth on each request */
;(async function checkAuth() {
  const storedToken = getCookie('token')

  const req = await fetch(
    `https://www.boag.online/api/cmsauth?pw=${storedToken}`
  )
  const json = await req.json()

  if (document.getElementById('login-container')) {
    if (json.auth) {
      window.location.href = '/'
    } else {
      document.getElementById('overlay').remove()
    }
  } else {
    if (!json.auth) {
      window.location.href = '/login'
    } else {
      document.getElementById('overlay').remove()
    }
  }
})()

const supabase = createClient(
  'https://axrrttjuhzejdeaggnqg.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF4cnJ0dGp1aHplamRlYWdnbnFnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ1NjQwODEsImV4cCI6MjAwMDE0MDA4MX0.KNS4gcgELu8aZkMUXjFkKnN28_vfR4uPpCqNO2E5GW4'
)

/* Fetch Data & Render Post List */
if (document.querySelector('#post-list')) {
  ;(async function fetchPosts() {
    const { data } = await supabase
      .from('posts')
      .select()
      .order('created_at', { ascending: false })
    let markup = ''
    data.forEach((p) => {
      markup += `
        <div>
            <span class="title">
              ${p.is_draft ? '<span class="draft-tag">Draft</span>' : ''}
              ${p.is_preview ? '<span class="preview-tag">Preview</span>' : ''}
              <a href="/edit#${p.id}">${p.title}</a>
            </span>
            <span class="date">${formatDate(p.created_at)}</span>
            <span class="tag">${p.tag}</span>
            <a data-action="delete" data-id="${
              p.id
            }" href="" class="delete">Delete</a>
        </div>
      `
    })
    document.querySelector('#post-list').innerHTML = markup

    /* Create and handle event listeners on delete links */
    document.querySelectorAll('a[data-action="delete"]').forEach((el) => {
      el.addEventListener('click', async (e) => {
        e.preventDefault()
        if (
          confirm(
            'This will delete this post and is not reversible, are you sure?'
          )
        ) {
          const delete_id = el.getAttribute('data-id')
          const { error } = await supabase
            .from('posts')
            .delete()
            .eq('id', delete_id)
          console.log('error', error)

          if (confirm('Would you like to trigger a Vercel build?')) {
            await triggerVercelBuild()
            location.reload()
          } else {
            location.reload()
          }
        }
      })
    })
  })()
}

/* Fetch Data & Populate Edit Post Form */
if (document.querySelector('#edit-post')) {
  const postId = window.location.hash.substring(1)
  ;(async function fetchSinglePost() {
    const { data } = await supabase.from('posts').select().eq('id', postId)

    document.querySelector('#edit-post #post-title').value = data[0].title
    document.querySelector('#edit-post #post-slug').value = data[0].slug
    document
      .querySelector('.view-post')
      .setAttribute(
        'href',
        `https://www.boag.online/notepad/post/${data[0].slug}`
      )
    document.querySelector('#edit-post #post-date').value = data[0].created_at
    document.querySelector('#edit-post #post-tag').value = data[0].tag
    document.querySelector('#edit-post #post-content').innerHTML =
      data[0].content
    document.querySelector('#edit-post #post-ogimage').value = data[0].ogImage
    document.querySelector('#edit-post #post-draft').checked = data[0].is_draft
    document.querySelector('#edit-post #post-preview').checked =
      data[0].is_preview

    if (data[0].is_draft) {
      document.querySelector('.button.draft').classList.remove('hidden')
      document.querySelector('.button.post').classList.add('hidden')
      document.querySelector('.preview-mode').classList.remove('hidden')
    }

    document.title = document.title.replace('Post Title', data[0].title)
  })()
}

/* When button is clicked, hit Vercel API to trigger a build */
if (document.querySelector('.trigger-vercel-build')) {
  document
    .querySelector('.trigger-vercel-build')
    .addEventListener('click', async (e) => {
      e.preventDefault()
      if (
        confirm('This will trigger a production Vercel deploy. Are you sure?')
      ) {
        document
          .querySelector('.trigger-vercel-build')
          .classList.add('disabled')

        await triggerVercelBuild()

        document
          .querySelector('.trigger-vercel-build')
          .classList.remove('disabled')
      }
    })
}

/* When link is clicked, generate slug from title */
if (document.querySelector('.generate-slug')) {
  document.querySelector('.generate-slug').addEventListener('click', () => {
    const title = document.querySelector('#post-title').value
    const slug = slugify(title)
    document.querySelector('#post-slug').value = slug
  })
}

/* When Add Post page is loaded, set date field to today's date */
if (document.querySelector('#add-post')) {
  document.getElementById('post-date').valueAsDate = new Date()
}

/* Handle Add Post form submission */
if (document.querySelector('#add-post')) {
  document
    .querySelector('#add-post form')
    .addEventListener('submit', async (e) => {
      e.preventDefault()

      const date = document.getElementById('post-date').value

      const postData = {
        title: document.getElementById('post-title').value,
        slug: document.getElementById('post-slug').value,
        created_at: date,
        year: formatDate(date, 'yearonly'),
        tag: document.getElementById('post-tag').value,
        content: document.getElementById('post-content').value,
        ogImage: document.getElementById('post-ogimage').value,
        is_draft: document.getElementById('post-draft').checked ? 1 : 0,
        is_preview: document.getElementById('post-preview').checked ? 1 : 0,
      }

      const { data, error } = await supabase
        .from('posts')
        .insert([postData])
        .select()

      console.log('data', data)
      console.log('error', error)

      alert('Post data sent to Supabase. Full response logged in console.')

      if (confirm('Would you like to trigger a Vercel build?')) {
        await triggerVercelBuild()
        window.location.href = '/'
      } else {
        window.location.href = '/'
      }
    })
}

/* Handle Edit Post form submission */
if (document.querySelector('#edit-post')) {
  document
    .querySelector('#edit-post form')
    .addEventListener('submit', async (e) => {
      e.preventDefault()

      const postId = window.location.hash.substring(1)

      const date = document.getElementById('post-date').value

      const postData = {
        title: document.getElementById('post-title').value,
        slug: document.getElementById('post-slug').value,
        created_at: date,
        year: formatDate(date, 'yearonly'),
        tag: document.getElementById('post-tag').value,
        content: document.getElementById('post-content').value,
        ogImage: document.getElementById('post-ogimage').value,
        is_draft: document.getElementById('post-draft').checked ? 1 : 0,
        is_preview: document.getElementById('post-preview').checked ? 1 : 0,
      }

      const { data, error } = await supabase
        .from('posts')
        .update(postData)
        .eq('id', postId)
        .select()

      console.log('data', data)
      console.log('error', error)

      alert(
        'Updated post data sent to Supabase. Full response logged in console.'
      )

      if (confirm('Would you like to trigger a Vercel build?')) {
        await triggerVercelBuild()
        window.location.href = '/'
      } else {
        window.location.href = '/'
      }
    })
}

/* Handle Login form submission */
if (document.querySelector('#login-container')) {
  document
    .querySelector('#login-container form')
    .addEventListener('submit', async (e) => {
      e.preventDefault()
      const submittedPassword = document.getElementById('password-field').value

      const req = await fetch(
        `https://www.boag.online/api/cmsauth?pw=${submittedPassword}`
      )
      const json = await req.json()

      if (json.auth) {
        setCookie('token', submittedPassword, 180)
        window.location.href = '/'
      } else {
        alert('Incorrect password')
      }
    })
}

/* Change draft/post button when checkbox changes */
if (document.querySelector('#post-draft')) {
  document.querySelector('#post-draft').addEventListener('change', () => {
    if (document.querySelector('#post-draft').checked) {
      document.querySelector('.button.draft').classList.remove('hidden')
      document.querySelector('.button.post').classList.add('hidden')
      document.querySelector('.preview-mode').classList.remove('hidden')
    } else {
      document.querySelector('.button.draft').classList.add('hidden')
      document.querySelector('.button.post').classList.remove('hidden')
      document.querySelector('.preview-mode').classList.add('hidden')
      document.querySelector('#post-preview').checked = false
    }
  })
}
